<template>
    <div class="content-wrapper">
        <div class="content">
            <div class="titleBox mb-5 text-center">
                Наша команда выполняет последние настройки. В течение дня ваш проект будет готов к работе.
            </div>
        </div>
    </div>
</template>

<script>
import OnBoarding from '@/components/project/onBoarding/OnBoarding.vue'
import useRouter from '@/hooks/useRouter'
import { useProject } from '@root/src/hooks/useProject'
export default {
    name: 'AuthorizationPending',
    components: {
        OnBoarding,
    },
    setup(props, { emit }) {
        const { project } = useProject()
        const router = useRouter()
        const goPrev = () => {
            router.router.push(`/project/${project.value.id}/onBoarding/AuthorizationInCRMSystem`)
            return
        }
        const goNext = () => {

        }
        return {
            goPrev,
            goNext
        }
    },
}
</script>
<style lang="sass" scoped>
.content-wrapper
    margin-top: 40px
    .content
        align-items: center
        justify-content: center
        display: flex
        flex-direction: column
        max-width: 81.25%
</style>