<template>
    <div class="content-wrapper">
        <div class="content">
            <div class="titleBox mb-5">
                Настройки CRM
            </div>
            <component
              v-if="activeComponent"
              :is="activeComponent"
            ></component>
        </div>
    </div>
</template>

<script>
import useRouter from '@/hooks/useRouter'
import { useProject } from '@root/src/hooks/useProject'
import settingsList from '@/components/connectorSettings/list'
import { computed, getCurrentInstance, PropType, ref } from 'vue'
import * as _ from 'lodash'
export default {
    name: 'ConnectorSettings',
    props: {
        crmConnectorType: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance().proxy
        const { project } = useProject()
        const router = useRouter()
        const goPrev = () => {
            router.router.push(`/project/${project.value.id}/onBoarding/AuthorizationInCRMSystem`)
            return
        }
        const  goNext = async (settings) => {
            if (_.isNil(settings)) settings = {}
            const loggedAddingConnector = ref(JSON.parse(sessionStorage.getItem('loggedAddingConnector')))
            settings.isDraft = false
            const connectorId = loggedAddingConnector.value.connectorId
            await root.$store.dispatch('project/updateConnectorSettings', { connectorId, settings })
            emit('refreshStep')
        }
        const activeComponent = computed(() => {
            return settingsList.find(el => el.connectorType === props.crmConnectorType)?.component
        })
        root.$on('createConnector', goNext)
        root.$on('emptySettings', goNext)
        return {
            goPrev,
            goNext,
            activeComponent
        }
    },
}
</script>
<style lang="sass" scoped>
.content-wrapper
    margin-top: 40px
    .content
        align-items: center
        justify-content: center
        display: flex
        flex-direction: column
        max-width: 81.25%
::v-deep .settings
    .cancel-btn
        display: none
</style>