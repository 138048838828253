<template>
    <div class="content-wrapper">
        <div class="content">
            <div v-if="currentTimezone" class="titleBox mb-5">
                Выберите вашу CRM систему
            </div>
            <div class="connectorsCardsLayout" v-if="currentTimezone">
                <ConnectorSettingsCard
                    class="pa-2"
                    v-for="(connector, index) in availableCrmSystems"
                    :key="index"
                    :connector="connector"
                    @selectConnector="selectConnector(connector)"
                ></ConnectorSettingsCard>
                <OtherConnectorCard
                  @selectConnector="selectConnector({name: 'Другое', type: 'other'})"
                />
            </div>
            <div v-else style="align-self: flex-start">
                <h1>Пожалуйста, выберите Ваш часовой пояс</h1>
            </div>
            <div
                class="whatsapp-settings-form__setting__value"
                :class="!currentTimezone ? 'unactive' : 'active'"
            >
                Часовой пояс: {{ currentTimezone ? currentTimezone : '' }}
                <TimeZonePicker
                    class="mt-2"
                    :value="value"
                    :recommendCurrentTimezone="true"
                    label="Выбрать"
                    @input="setTimezone"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { useProject } from '@/hooks/useProject'
import ConnectorSettingsCard from '@/components/project/onBoarding/ConnectorSettingsCard.vue'
import { getCurrentInstance, computed, ref, onMounted } from 'vue'
import OnBoarding from './OnBoarding.vue'
import OtherConnectorCard from './OtherConnectorCard.vue'
import TimeZonePicker from '../../ui/TimeZonePicker.vue'
import useRouter from '../../../hooks/useRouter'

export default {
    name: 'SelectCRMSystem',

    components: {
        TimeZonePicker,
        OnBoarding,
        ConnectorSettingsCard,
        OtherConnectorCard,
    },

    setup(props, { emit }) {
        const value = Object()
        const { project } = useProject()
        const root = getCurrentInstance().proxy
        const store = root.$store
        const router = useRouter()
        const connectorsList = store.getters.connectorsHashMapWithKeysByConnectorsType
        const currentTimezone = ref(null)

        const crmSystems = [
            "cloud1C",
            "profSalonBat",
            "universeSoft",
            "dentalPro",
            "archimed",
            "sycret",
            "medesk",
            "altegio",
            "arnicaV2",
            "dikidi",
            "klientix",
            "yClients",
            "drsoft",
        ];

        const setTimezone = (timezone) => {
            currentTimezone.value = timezone
            sessionStorage.setItem('projectTimeZone', timezone)
        }

        const getAvailableCrmSystems = (connectorsList) => {
            const availableCrmSystems = {};
            crmSystems.forEach((system) => {
                if (connectorsList.hasOwnProperty(system)) {
                    availableCrmSystems[system] = connectorsList[system];
                }
            });
            return availableCrmSystems;
        };

        const availableCrmSystems = computed(() => getAvailableCrmSystems(connectorsList))
        onMounted(async () => {
            const timezone = sessionStorage.getItem('projectTimeZone')
            if (timezone) {
                currentTimezone.value = timezone
            }
        })

        const selectConnector = (connectorType) => {
            window.localStorage.setItem(project.value.id + '-onboardingSelectedCrm', JSON.stringify(connectorType))
            store.commit('project/updateCurrentAddingConnector', connectorType.type)
            emit('refreshStep')
        }

        return {
            setTimezone,
            value,
            currentTimezone,
            availableCrmSystems,
            selectConnector
        }
    },
}
</script>
<style lang="sass" scoped>
.connectorsCardsLayout
    display: grid
    grid-template-columns: repeat(5, 1fr)
    gap: 16px
    max-width: 1200px
    margin: 0 auto

.whatsapp-settings-form__setting__value
    border-radius: 4px
    padding: 20px
    margin-top: 20px
    align-self: flex-start
    display: flex
    flex-direction: column
    align-items: flex-start

    .unactive
        border: 1px solid red
    .active
        border: 1px solid #2A3B511F

    .content-wrapper
        margin-top: 40px
    .content
        align-items: center
        justify-content: center
        display: flex
        flex-direction: column
        max-width: 81.25%
</style>